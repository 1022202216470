
import { Vue, Component, Prop } from "vue-property-decorator";
import Divider from "@/components/ewa/common/Divider.vue";

@Component({
  components: { Divider },
})
export default class Reports2EditSavedFilterModal extends Vue {
  @Prop() action: (name: string, id?: string) => boolean;
  @Prop() savedName: string;
  @Prop() savedId: string;

  @Prop() title?: string;

  nameModel: string = null;

  mounted() {
    this.nameModel = this.savedName;
  }

  async saveFilter() {
    if (!this.nameModel && !this.nameModel.length) {
      return (window as any).error("Введите название фильтра");
    }
    if (this.nameModel.length < 3 || this.nameModel.length > 50) {
      return (window as any).error(
        "Название фильтра должно содержать от 3 до 50 символов."
      );
    }

    await this.action(this.nameModel, this.savedId);
    this.close();
  }

  close() {
    (this.$root as any).closeDialog();
  }
}
